import React from 'react'
// import {Link} from 'react-router-dom'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import GoingPlacesMarquee from '../components/GoingPlacesMarquee'

// Assets
import BioPhoto from '../assets/images/bio-promo.jpg'
import Promo1 from '../assets/images/promo1.jpg'
import Promo2 from '../assets/images/promo2.jpg'
import BlissNovaLive from '../assets/images/bliss-nova-live.jpg'
// import GoingPlacesFull from '../assets/images/going-places/going-places-full.png'
import BlissPointDown from '../assets/images/bliss-nova-point-down.jpg'
import SMPattern from '../assets/images/sm-pattern.jpg'
import Joel from '../assets/images/joel-trzcinski.jpg'
import Dan from '../assets/images/daniel-trzcinski.jpg'
// import GoingPlacesCover from '../assets/images/going-places/going-places-vinyl-cover-mock.png'

// Styled Components
const BioHero = styled.section`
  background-image: url(${BioPhoto});
  background-position: center;
  background-size: cover;
  min-height: 80vh;
  min-width: 100vw;
  padding: 5em 0;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    bottom: -0.1em;
    left: 0;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0), black);
    height: 50vh;
  }
`

const HighlightedP = styled.p`
  font-size: 1.25em;
  font-weight: 500;
  padding: 2px;
  position: relative;
  z-index: 1;
  color: white;

  em {
    font-family: "Quincy CF Medium Italic";
  }

  ${queryBelow("bigPhone", css`
    font-size: 1.1em;
  `)}
`

const BioCenterWrap = styled.div`
  max-width: 38em;
  margin: 0 auto -4em;
  width: 80%;
  z-index: 1;
`

const BioHeading = styled.h1`
  font-size: 18.5vw;
  line-height: 1;
  margin: 0;
  position: relative;
  user-select: none;
  text-transform: uppercase;
  width: 100%;
  color: ${colors.calloutAlt};
  mix-blend-mode: color-dodge;

  span {
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.callout};
    -webkit-text-stroke-width: 2px;
    color: #FFFFFF;
    background-position: top center;
    background-size: cover;
    color: transparent;
    display: inline-block;
    font-family: "Eighties";
    font-weight: lighter;
    line-height: 0.7;
    mix-blend-mode: lighten;
    position: relative;
    width: 4em;

    &:last-of-type {
      text-align: right;
      top: -0.6em;
      float: right;
    }
  }
`

const BioSection = styled.section`
  padding: 10em 0;
  z-index: 2;

  ${queryBelow("bigPhone", css`
    padding: 0 0 10em;
  `)}

  .br {
    display: block;
    margin-bottom: 1em;
    width: 100%;
  }
`

const ImageContainer = styled.div`
  display: inline-block;
  margin: -3em;
  position: relative;
  width: 60%;
  height: auto;

  ${queryBelow("bigPhone", css`
    display: block;
    width: 100%;
    margin: 3em 0;
  `)}

  img {
    display: inline-block;
    height: auto;
    width: 100%;
  }

  &:before {
    content: "";
    background-color: ${colors.callout};
    position: absolute;
    left: -1em;
    bottom: -1em;
    height: 100%;
    width: 5em;
  }

  &:nth-of-type(even) {
    &:before {
      background-color: ${colors.calloutAlt};
    }
  }
`

const BioHeaderContainer = styled.section`
  background-color: black;
  background-image: url(${BlissNovaLive});
  background-repeat: repeat;
  background-size: 400px;
  margin: -7em 0 0;

  h2 {
    bottom: -1.5em;
    color: ${colors.calloutAlt};
    font-family: "Eighties";
    font-size: 13vw;
    font-weight: lighter;
    left: -1.1em;
    line-height: 0.7;
    mix-blend-mode: color-dodge;
    padding: 0.2em;
    position: absolute;
    text-transform: uppercase;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.callout};
    -webkit-text-stroke-width: 2px;
    color: #FFFFFF;
    top: -1.2em;
    user-select: none;
    z-index: 9;

    ${queryBelow("bigPhone", css`
      font-size: 20vw;
      top: -3.4em;
    `)}

    span {
      font-size: 24vw;
      line-height: 0.4;

      ${queryBelow("bigPhone", css`
        font-size: 65vw;
      `)}
    }
  }
`

const OnToSomething = styled.section`
  background: ${colors.calloutAltBright};
  padding-bottom: 0;
`

const MediumWrap = styled.div`
  margin: 0 auto;
  display: flex;
  max-width: 52em;
  width: 90%;
  position: relative;

  ${queryBelow("bigPhone", css`
    display: block;
    width: 75%;
  `)}
`

const ColumnWrap = styled.div`
  display: block;
  columns: 2;
  column-gap: 2em;

  ${queryBelow("bigPhone", css`
    columns: 1;
  `)}

  p {
    font-weight: 500;
    break-inside: avoid;
  }

  .br {
    display: block;
    margin-bottom: 1em;
    width: 100%;
  }
`

const TextContainer = styled.div`
  flex-direction: column;
  position: relative;
  width: 54%;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    width: 100%;
  `)}
`

const StyledParagraph = styled.p`
  display: inline;
  background-color: ${colors.black};
  color: ${colors.white};
  padding: 2px;
  width: calc(100% + 3em);

  &, em {
    font-weight: 500;
  }
`

const StyledParagraphTwo = styled(StyledParagraph)`
  background-color: ${colors.white};
  color: ${colors.type};
  width: 100%;
`

const GoingPlacesBio = styled.section`
  background: linear-gradient(${colors.calloutAltBright}, #2258AE);
  color: white;
  padding-bottom: 0;

  &:after {
    content: "";
    position: absolute;
    bottom: -5em;
    left: 0;
    width: 100%;
    background-image: url(${SMPattern});
    mix-blend-mode: darken;
    height: 10em;
    z-index: 1;
  }

  p {
    display: inline;
  }

  img {
    margin-bottom: 2em;
  }

  svg {
    width: 100%;
    z-index: 1;
  }
`

const BioGoingPlacesMarquee = styled(GoingPlacesMarquee)`
  margin-bottom: 4em;
`

const GoingPlacesSVG = styled.svg`
  margin-bottom: 2em;

  &, use {
    fill: black;
    mix-blend-mode: overlay;
  }
`

const GoingPlacesBioHeading = styled.h4`
  text-align: center;
  margin-bottom: 0;
`

const GoingPlacesBioHeader = styled.div`
  flex-direction: column;
  justify-content: center;
`

const AlbumArtCaption = styled.div`
  color: black;
  mix-blend-mode: overlay;
  position: relative;
  bottom: 4em;
  left: 0;
  top: auto;

  a {
    color: black;

    &:hover {
      color: white;
    }
  }
`

const BioGoingPlacesText = styled.div`
  display: block;
  position: relative;
  z-index: 2;

  .br {
    display: block;
    margin-bottom: 1em;
    width: 100%;
  }
`

const DevilishGoofy = styled.section`
  background-image: url(${BlissPointDown});
  background-position: top center;
  padding-top: 32em;

  ${queryBelow("bigPhone", css`
    background-size: contain;
  `)}

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(rgba(0,0,0,0), black);
    height: 100vh;
  }
`

const Band = styled.section`
  padding-top: 0;

  &:before {
    background-image: url(${BlissPointDown});
    position: absolute;
    top: -4em;
    width: 100%;
    content: "";
    height: 10vh;
    background-size: contain;
    mix-blend-mode: luminosity;
  }

  .br {
    display: block;
    margin-bottom: 1em;
    width: 100%;
  }

  h2 {
    font-family: "Eighties";
    font-size: 13vw;
    font-weight: lighter;
    left: -0.4em;
    line-height: 0.7;
    mix-blend-mode: color-dodge;
    padding: 0.2em;
    position: relative;
    text-transform: uppercase;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.callout};
    -webkit-text-stroke-width: 2px;
    color: #FFFFFF;
    width: 70%;
    z-index: 1;

    ${queryBelow("tablet", css`
      left: 0;
      width: 100%;
    `)}
  }
`

const BandIntroWrap = styled.div`
  color: #FFFFFF;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    flex-direction: column;
  `)}
`

const BITText = styled.div`
  position: relative;
  width: 50%;

  ${queryBelow("bigPhone", css`
    width: 80%;
  `)}
`

const BandMates = styled.div`
  justify-content: space-between;
  align-items: baseline;
  position: relative;
  top: -14em;
  margin-bottom: -9em;

  ${queryBelow("phone", css`
    top: -11em;
    flex-direction: column;
  `)}

  h2 {
    color: ${colors.calloutAlt};
    font-family: "Eighties";
    font-size: 5em;
    font-weight: lighter;
    left: -1.1em;
    line-height: 0.7;
    mix-blend-mode: difference;
    padding: 0.2em;
    text-transform: uppercase;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.calloutQuat};
    -webkit-text-stroke-width: 2px;
    color: #FFFFFF;
    width: 70%;
    z-index: 1;

    top: auto;
    font-size: 4em;
    position: absolute;
    bottom: 4rem;

    ${queryBelow("phone", css`
      left: 0;
      width: 100%;

    `)}
  }

  p {
    position: absolute;
    z-index: 1;
    bottom 1em;
    color: #FFF;
    left: 1em;
  }
`

const BandMate = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;

  ${queryBelow("tablet", css`

  `)}

  a {
    color: white;

    &:hover {
      color: #ef4469;
    }
  }
`

const BandMateImage  = styled.img`
  height: auto;
  width: 100%;
`

// Bio
function Bio() {

  return (
    <>
      <Header/>
      <BioHero>
        <div className="wrap">
          <BioHeading>
            <span>Synth Pop</span>
            <br></br>
            <span>Dream Devils</span>
          </BioHeading>
        </div>
        <BioCenterWrap>
          <HighlightedP>A seductive state of Bliss Nova is spreading. Witnesses to this modern wonder have reported swaths of pop-driven <em>synth</em>onies infiltrating Ohio’s DIY party circuit while technicolor funk grooves and stirring hooks of psychedelic electronica bleed from a Toledo city basement. Those responsible? Two brothers set to ease the world’s tension through their own cosmic philosophy — to move the masses and dance with the devil’s advocate.</HighlightedP>
        </BioCenterWrap>
      </BioHero>
      <BioSection>
        <MediumWrap>
          <ImageContainer>
            <span className="caption">Photo by Jake Weber</span>
            <img alt="Bliss Nova - Captured by Jake Weber" src={Promo1} />
          </ImageContainer>
          <TextContainer>
            <StyledParagraph>
              Brothers in arms, melody and blood, Bliss Nova ask more questions than they have answers. Channeling a dreamy internal monologue entwined with the spirit of French philosophy, their smoggy '80s-tinged euphoria is awash with vintage sparkle.
            </StyledParagraph>
            <div className="br"></div>
            <StyledParagraph>
              Suited equally to cocktails on the beach or driving by a neon-lit city skyline, DJs file their synesthetic synth-pop alongside Toro y Moi, Washed Out and Tame Impala, yet the band’s outsider origins defy expectations.
            </StyledParagraph>
            <div className="br"></div>
            <StyledParagraph>
              Through their devout Christian upbringing as kids, Daniel and Joel Trzcinski attended an evangelical Pentecostal school where their mother played and taught piano. When the lights were turned off at the end of the day, the pair would sneak into the church to tinker on bass, saxophone, keyboards — any instruments they could lay their hands on.
            </StyledParagraph>
          </TextContainer>
        </MediumWrap>
        <MediumWrap>
          <TextContainer>
            <StyledParagraph>
              Growing up they'd secretly swap secular albums with friends, visit record stores to listen to The Beatles or The Carpenters — their parents’ favorite bands before they got saved — and quietly tune in to local college radio at midnight, hoping they wouldn't get caught.
            </StyledParagraph>
            <div className="br"></div>
            <StyledParagraph>
              Both learned piano from their mother. Daniel, the youngest of the siblings, sought expression by delving into the symphonies of Bach, Debussy and Chopin. Five years older and preferring the idea of performance, Joel played the themes of favorite movies <em>Mission Impossible</em> and <em>Batman</em> until another instrument seized his attention.
            </StyledParagraph>
            <div className="br"></div>
            <StyledParagraph>
              “The church’s amped-up music energized an entire congregation. Drums were a big part of that,” Joel recalls. “When I first discovered Nirvana and Dave Grohl’s drumming, it set my brain on fire. I so badly wanted to be a badass drummer like him, to entertain and move a crowd.”
            </StyledParagraph>
          </TextContainer>
          <ImageContainer>
            <span className="caption">Photo by Jake Weber</span>
            <img alt="Bliss Nova - Captured by Jake Weber" src={Promo2} />
          </ImageContainer>
        </MediumWrap>
      </BioSection>
      <BioHeaderContainer>
        <div className="caption">Photo by Leo Mascaro</div>
        <MediumWrap>
          <h2><span>On to</span> Something</h2>
        </MediumWrap>
      </BioHeaderContainer>
      <OnToSomething>
        <ColumnWrap className="medium-wrap">
          <p>Bliss Nova’s musical awakening wouldn’t stay secret for long. No rival to hip New York or Chicago, Toledo is “in the middle of nowhere and everywhere,” says chief-songwriter Daniel. “People can easily feel isolated.”</p>
          <p>But it ignited the band’s spark. Honing their craft under earlier musical endeavors, sonic force eventually welded the pair’s brotherly bond tighter and Bliss Nova was formed. With their reputation growing, they swapped their MIDI-based home recording sessions for a fully fleshed-out debut EP and live shows, and the response was resounding.</p>
          <p>The Alternative selected their debut EP, Do You Feel, as one of the best EPs of 2015, lauding Bliss Nova as one of the top promising acts from the rising Midwest scene.</p>
          <p>And GoldFlakePaint extended its Midas touch, premiering songs from the duo’s self-released second EP, Light & Shadow.</p>
          <p>They’ve also charmed online video maverick channel ‘80s Ladies, who add the band’s melodic otherworldliness to retro movie clips on YouTube, where they’ve attracted over 60 million views and fans from around the world.</p>
          <p>Not bad for a self-managed duo, opting to do it all themselves, from sourcing artwork to plotting tours.</p>
        </ColumnWrap>
      </OnToSomething>
      <GoingPlacesBio>
        <GoingPlacesBioHeader className="medium-wrap">
          <GoingPlacesBioHeading>The Debut Album</GoingPlacesBioHeading>
          <GoingPlacesSVG>
            <use xlinkHref="#going-places-logo" />
          </GoingPlacesSVG>
        </GoingPlacesBioHeader>
        <BioGoingPlacesMarquee />
        <BioGoingPlacesText className="small-wrap">
          <AlbumArtCaption className="caption">
            Artwork & design by <a href="https://www.instagram.com/brandonpaxton/" target="_blank" rel="noopener noreferrer">Brandon Paxton</a>.
            Cover art photography by <a href="https://www.instagram.com/stephpaxton/" target="_blank" rel="noopener noreferrer">Stephanie Paxton</a>.
            Band photography by <a href="https://www.instagram.com/skateforjake/" target="_blank" rel="noopener noreferrer">Jake Weber</a>.
          </AlbumArtCaption>
          <p>Their debut LP, Going Places, builds upon the brothers’ passion of sharing music as kids, but is an exploration into existential adulting. Offering a ‘third stage’ perspective like that suggested by Paul Ricœur’s second naïveté, the record’s narrative arc speaks of romantic fatalism — or fatalistic romanticism — trying to enjoy life’s impossible process and find happiness in endless struggle, drawing upon Albert Camus’s The Myth of Sisyphus.</p>
          <div className="br"></div>
          <p>[The album] is dreamy in both the hopeful and pensive sense,” Daniel notes. “It’s about sending a wish into the universe and getting it back 'return to sender.’ Longing to make something of yourself, to move toward a place where your past, present and future are reconciled — but still spinning your wheels, driving down an endless highway toward an impossible horizon.”</p>
          <div className="br"></div>
          <p>With the new-age aesthetic of Brian Eno and Gondry-esque surrealism, the album’s ethereal splendor marks the freshest findings of Bliss Nova’s sonic evolution.</p>
          <div className="br"></div>
          <p>Late '70s grooves grind against hints of early '80s electro funk and cosmic jazz. Timbres and textures of '80s ambient soundscapes and '90s electronica merge with '00s neo-soul and r&b. A supernova of samples, field recordings, lush percussion, and catchy pentatonic tropes shimmer on the surface, with murkier depths and humor underneath.</p>
        </BioGoingPlacesText>
      </GoingPlacesBio>
      <DevilishGoofy>
        <BioGoingPlacesText className="small-wrap">
          <StyledParagraphTwo>
            “We have a devilish, goofy side that grew out of being stuck inside a serious environment our entire lives,” tells Daniel. “To let off steam we’ve always developed inside jokes, and that shows up in the music. On Going Places we used a number of bizarre samples playing with spirituality and destiny. It’s tongue-in-cheek but serious… while seriously poking fun.”
          </StyledParagraphTwo>
          <div className="br"></div>
          <StyledParagraphTwo>
            Joel adds, “I feel like we’re most comfortable embracing our weirdness, with each new thing we do revealing more of who we really are.”
          </StyledParagraphTwo>
          <div className="br"></div>
          <StyledParagraphTwo>
            A solid case for enticing a diverse cohort of collaborators. Connecting via Twitter, MUTEMATH’s Paul Meany became a mentor for the band (they wrote many of the album’s songs after receiving feedback on demos they’d sent him). Cascine’s Erika Spring (Au Revoir Simone, David Lynch’s Twin Peaks) lends her silky-smooth vocals on the single “Blind” from the new record, while Stones Throw collaborator and legendary mastering mind Dave Cooley (J Dilla, Blood Orange, M83) has given the tracks a beautiful, polished sheen.
          </StyledParagraphTwo>
        </BioGoingPlacesText>
      </DevilishGoofy>
      <Band>
        <BandIntroWrap className="medium-wrap">
          <h2>Take a chance on Bliss Nova</h2>
          <BITText>
            <StyledParagraphTwo>The Trzcinski brothers’ Bliss Nova philosophy truly is Going Places and moving a swelling congregation of their own followers in the process.</StyledParagraphTwo>
            <div className="br"></div>
            <StyledParagraphTwo>“Music is about communication,” tells Daniel of their audio-visual vocabulary. “And sometimes your unconscious knows what to say better than what you think.”</StyledParagraphTwo>
          </BITText>
        </BandIntroWrap>
      </Band>

      <BandMates className="medium-wrap">
        <BandMate>
          <BandMateImage src={Dan} />
          <h2>Daniel Trzcinski</h2>
          <p>vocals, synths, bass, electric guitar, samples</p>
        </BandMate>
        <BandMate>
          <BandMateImage src={Joel} />
          <h2>Joel Trzcinski</h2>
          <p>drums, percussion</p>
          <span className="caption">Photos by <a href="https://www.instagram.com/snowfoxphotography/" target="_blank" rel="noopener noreferrer">Snowfox Photography</a></span>
        </BandMate>
      </BandMates>
      <Footer />
    </>
  );
}

export default Bio;
