import React, {Component} from 'react'
import styled from 'styled-components'

// Utilities
// import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const VideoWrapper = styled.div`
  height: 0;
  margin: 1em 0 2em;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;

  iframe {
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }
`

class VideoContainer extends Component {
  render() {
    return (
      <VideoWrapper>
        {this.props.children}
      </VideoWrapper>
    );
  }
}

export default VideoContainer;
