import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'
// import SpotifyPlayer from 'react-spotify-player';

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Assets
import SilenceImage from '../../assets/images/bliss-nova-silence-ep.jpg'

// Components
import AlbumCallsToAction from '../AlbumCallsToAction'

// Styled Components
const SilenceCalloutSection = styled.section`
  background-color: rgba(0,0,0,0.96);
  padding: 0;
`

const CalloutWrap = styled.div`
  margin: 2em auto;
  padding: 1em;
  position: relative;
  width: 100%;

  ${queryBelow("bigPhone", css`
    padding: 2em 0;
    width: 100%;
  `)}

  // &:before {
  //   background-image: linear-gradient(-105deg, ${colors.callout}, ${colors.calloutAlt});
  //   content: "";
  //   height: calc(100% + 2em);
  //   left: 0;
  //   opacity: 0.86;
  //   position: absolute;
  //   top: 0;
  //   width: 100%;
  // }

  &:after {
    background-blend-mode: multiply;
    background-color: #22353c;
    background-image: url(${SilenceImage});
    background-position: center center;
    background-repeat: no-repeat;
    content: "";
    filter: blur(10em);
    height: 100%;
    left: 0;
    // opacity: 0.9;
    position: absolute;
    top: 0;
    width: calc(100% - 2em);
    margin: 1em;
  }
`

const CalloutHeader = styled.div`
  color: ${colors.white};
  margin: 4em 0 3em;
  position: relative;
  text-align: center;
  z-index: 1;
`

const CalloutHeading = styled.h4`
  color: ${colors.white};
  margin: 0 0 -0.5em;
`

const ContentContainer = styled.div`
  display: flex;
  margin: 0 auto;
  position: relative;
  justify-content: space-between;
  max-width: 54em;
  z-index: 1;

  ${queryBelow("tablet", css`
    max-width: 100%;
  `)}

  ${queryBelow("bigPhone", css`
    align-items: center;
    flex-direction: column;
  `)}

  p {
    color: ${colors.white};
    font-family: "Quincy CF";
    font-size: 1.2em;
    font-weight: 400;
  }
`

const AlbumCallsToActionContainer = styled.div``

const Writeup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  max-width: 30em;
  width: 55%;

  ${queryBelow("tablet", css`
    margin-left: 0;
    width: 100%;
  `)}
`

const CoverArt = styled.img`
  height: 100%;
  width: 100%;
  z-index: 99;
  margin: 0 auto 1rem;
  display: block;
  max-width: 54em;

  ${queryBelow("tablet", css`
    max-width: 100%;
  `)}
`

// Logo
class SilenceCallout extends Component {
  render() {
    const { writeup } = this.props;

    return(
      <SilenceCalloutSection>
        <CalloutWrap>
          <CalloutHeader>
            <CalloutHeading>Silence EP</CalloutHeading>
          </CalloutHeader>
          <CoverArt src={SilenceImage} alt="Bliss Nova Silence EP Cover Art" />
          <ContentContainer>
            <AlbumCallsToActionContainer>
              <AlbumCallsToAction
                spotifyAlbumCode="3WYKbMxxPl7UXc0mjj6VCx"
                mainCtaText="Stream or Buy"
                mainCtaHref="https://ffm.to/silence-ep"
              />
            </AlbumCallsToActionContainer>
            {
              writeup && (
                <Writeup dangerouslySetInnerHTML={{ __html: writeup }} />
              )
            }
          </ContentContainer>
        </CalloutWrap>
      </SilenceCalloutSection>
    )
  }
}

export default SilenceCallout
