import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors } from "../utilities"

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'

// Styled Components
const TourContainer = styled.div`
  color: ${colors.white};
  flex-direction: column;
  padding: 10em 0 4em;
  text-align: center;

  h1 {
    margin-bottom: 0.4em;
  }
`

const TourWrap = styled.div`
  flex-direction: column;
`

class Tour extends Component {
  render() {
    return (
      <>
        <Header />
        <TourContainer>
          <TourWrap className="wrap">
            <h4>Tour</h4>
            <h1>There are currently no upcoming shows.</h1>
            <p>Keep in touch via our newsletter for updates on live performance dates and more.</p>
          </TourWrap>
        </TourContainer>
        <Footer />
      </>
    );
  }

}

export default Tour;
