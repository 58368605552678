import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Assets
// import logo from  '../../assets/images/bliss-nova-logo.svg'
import ElectricFunLogo from  '../../assets/images/ElectricFun.svg'

// Components
import SocialNav from "../SocialNav"

// Helpers
let newDate = new Date()
let year = newDate.getFullYear()

// Styled Components
const StyledFooter = styled.footer`
  background-color: ${colors.black};
  display: inline-block;
  padding: 4em 0;
  width: 100%;
  position: relative;
  z-index: 1;

  .wrap {
    display: flex;
    flex-direction: column;
  }
`

const ContactInfo = styled.div`
  color: ${colors.white};
  line-height: 1.2;
  letter-spacing: 0.01em;
  margin-bottom: 4em;
  border-top: 1px dotted ${colors.callout};
  border-bottom: 1px dotted ${colors.callout};
  padding: 1em;
  text-align: center;

  h4 {
    color: ${colors.callout};
    letter-spacing: 0.6em;
    margin: 0 0 0.6em;
  }

  p {
    letter-spacing: 1px;
    margin-bottom: 0;
  }
`

const LogoLink = styled(Link)`
  display: none;
  height: 2em;
  margin: 0 auto 1.6em;
  width: 8em;

  svg {
    height: 100%;
    width: 100%;

    &, use {
      fill: ${colors.white};
    }
  }
`

const Copyright = styled.div`
  color: rgba(255,255,255,0.5);
  font-family: "Acumin Pro";
  font-size: 0.8em;
  margin-bottom: 2em;
  text-align: center;
  justify-content: center;
  width: 100%;
`

const ElectricFun = styled.a`
  background-image: url("${ElectricFunLogo}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  height: 3em;
  width: 6.2em;
  margin: 0 auto;
`

// Logo
class Footer extends Component {
  render() {
    return(
      <StyledFooter>
        <div className="wrap">

          <LogoLink to="/">
            <svg><use xlinkHref="#logo" /></svg>
          </LogoLink>

          <ContactInfo>
            <h4>Contact</h4>
            <p>For all inquiries, please contact <a href="mailto:blissnovaband@gmail.com">blissnovaband@gmail.com</a>.</p>
          </ContactInfo>
          <SocialNav />
          <Copyright>&copy; {year} Bliss Nova. All Rights Reserved.</Copyright>
        </div>
        <ElectricFun href="https://electricfun.io" target="_blank" rel="noopener noreferrer" title="Website by Electric Fun • Meaningful digital experiences." />
      </StyledFooter>
    )
  }
}

export default Footer
