// Brand Colors
export const callout = "#ED5953"
export const calloutSecondary = "#EF4469"
export const calloutAlt = "#F6B173"
export const calloutSecondaryAlt = "#EB9E39"
export const calloutAltBright = "#F4D0AC"
export const calloutTert = "#405398"
export const calloutQuat = "rgba(255,255,2)"
export const white = "#FFFFFF"
export const type = "#242424"
export const black = "#000000"
