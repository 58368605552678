import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled, { css } from 'styled-components';

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import LogoLink from '../../components/LogoLink'

// Styled Components
const Navigation = styled.nav`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 1em;
  position: relative;
  width: 100%;
  z-index: 99;

  ${queryBelow("bigPhone", css`
    display: none;
  `)}

`

const NavLinksContainer = styled.div`
  display: inline-flex;
  width: 28em;

  &:first-of-type {
    justify-content: flex-end;
  }

  &:last-of-type {
    justify-content: flex-start;
  }
`

const NavItem = styled(NavLink)`
  color: rgba(255,255,255, 0.5);
  font-family: "Eighties";
  font-size: 1.2em;
  letter-spacing: 0.1em;
  padding: 1em;
  position: relative;
  text-transform: uppercase;

  ${queryBelow("bigPhone", css`
    font-size: 1vw;
  `)}

  &:hover {
    color: ${colors.calloutAltBright};
  }

  &.active {
    animate: all 0.2s ease-in-out;
    color: ${colors.white};

    &:after {
      background-color: ${colors.callout};
      bottom: 1em;
      content: "";
      height: 0.4em;
      left: 0;
      margin: 0 1em;
      position: absolute;
      width: calc(100% - 2.1em);
      z-index: -1;
    }
  }
`

const NavItemExternal = styled.a`
  color: rgba(255,255,255, 0.5);
  font-family: "Eighties";
  font-size: 1.2em;
  letter-spacing: 0.1em;
  padding: 1em;
  position: relative;
  text-transform: uppercase;

  ${queryBelow("bigPhone", css`
    font-size: 1vw;
  `)}

  &:hover {
    color: ${colors.calloutAltBright};
  }
`

// Logo
class Nav extends Component {
  render() {
    return(
      <Navigation>
        <NavLinksContainer>
          <NavItem to="/bio">
            <span>Biography</span>
          </NavItem>
          <NavItem to="/video">
            <span>Video</span>
          </NavItem>
        </NavLinksContainer>

        <LogoLink />

        <NavLinksContainer>
          <NavItem to="/discography">
            <span>Discography</span>
          </NavItem>
          <NavItemExternal href="https://blissnova.bigcartel.com/" target="_blank" >
            <span>Merch</span>
          </NavItemExternal>
        </NavLinksContainer>
      </Navigation>
    )
  }
}

export default Nav
