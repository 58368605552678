import React, { Component } from 'react'
import styled, { css } from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const SocialNavContainer = styled.div`
  align-items: center;
  display: inline-flex;
  font-size: 1.2em;
  margin-bottom: 1em;

  a {
    animation-duration: 1.5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
    font-family: "SSSocialRegular";
    color: ${colors.white};
    padding: 0.6em;

    ${queryBelow("phone", css`
      padding: 0.4em;
    `)}

    &:hover {
      animation-name: link-color-animation;
    }
  }
`

// Logo
class SocialNav extends Component {
  render() {
    return(
      <SocialNavContainer className={this.props.className}>
        <a href="https://www.facebook.com/BlissNovaMusic/" target="_blank" rel="noopener noreferrer">Facebook</a>
        <a href="https://twitter.com/blissnovaband" target="_blank" rel="noopener noreferrer">Twitter</a>
        <a href="https://www.instagram.com/bliss_nova/" target="_blank" rel="noopener noreferrer">Instagram</a>
        <a href="http://www.youtube.com/c/BlissNova" target="_blank" rel="noopener noreferrer">YouTube</a>
        <a href="https://soundcloud.com/bliss-nova" target="_blank" rel="noopener noreferrer">Soundcloud</a>
        <a href="https://open.spotify.com/artist/0TdnFTtsWOGfCChmPmjXZe" target="_blank" rel="noopener noreferrer">Spotify</a>
        <a href="https://itunes.apple.com/us/artist/bliss-nova/id997731727" target="_blank" rel="noopener noreferrer">Apple</a>
      </SocialNavContainer>
    )
  }
}

export default SocialNav
