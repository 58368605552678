import React, { useEffect } from 'react';

import ReactGA from 'react-ga'
import './App.scss';

// Components
import router from './components/Routes'
// import SubscribeCallout from './components/SubscribeCallout'
// import Error404 from './components/Error404'

// Utilities
// import { queryBelow } from "./utilities/mediaQuery"
// import ScrollToTop from "./utilities/ScrollToTop"

// Styled Components
// const AppContainer = styled.div``

// const PrimaryContent = styled.div`
//   display: inline-block;
//   min-height: 100vh;
//   position: absolute;
//   transition: filter 0.5s ease;
//   width: 100%;
//   top: 0;
//   overflow: hidden;

//   &.blurred {
//     ${queryBelow("bigPhone", css`
//       filter: blur(140px);
//     `)}
//   }
// `

// App Container
function App() {
  useEffect(() => {
    ReactGA.initialize("UA-150199318-1");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [])

  // return (
  //   <Router >
  //     <AppContainer className="App">
  //       <SVGSprite />
  //       <PrimaryContent className={menuOpen ? 'blurred' : null}>
  //         <Routes location={location} />
  //         <SubscribeCallout />
  //         <Footer />
  //       </PrimaryContent>

  //     </AppContainer>
  //   </Router>
  // );
}

export default App;
