import React from 'react';
import styled from 'styled-components'

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const MenuButtonContainer = styled.div`
  --color: ${colors.white};
  align-items: center;
  display: flex;
  height: 5em;
  justify-content: center;
  left: 0;
  padding: 1em 2em;
  position: absolute;
  top: 0;
  width: 7em;
  z-index: 9;

  svg {
    fill: none;
    stroke: var(--color);
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;

    use {
      &:nth-of-type(1) {
        opacity: 1;
        stroke-dashoffset: 221;
        stroke-dasharray: 46 249;
        transition: stroke-dashoffset .12s linear .2s, stroke-dasharray .12s linear .2s, opacity 0s linear .2s;
      }

      &:nth-of-type(2) {
        animation: stroke-animation-reverse 1.2s ease-out forwards;
      }
    }
  }

  input {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    cursor: pointer;
    opacity: 0;
    z-index: 9;

    &:checked {
      + svg {
        use {
          &:nth-of-type(1) {
            stroke-dashoffset: 175;
            stroke-dasharray: 0 295;
            opacity: 0;
            transition: stroke-dashoffset .07s linear .07s, stroke-dasharray .07s linear .07s, opacity 0s linear .14s;
          }

          &:nth-of-type(2) {
            animation: stroke-animation 1.2s ease-out forwards;
          }
        }
      }
    }

  }
}

@keyframes stroke-animation {
  0% {
    stroke-dashoffset: 295;
    stroke-dasharray: 25 270;
  }
  50% {
    stroke-dashoffset: 68;
    stroke-dasharray: 59 236;
  }
  65% {
    stroke-dashoffset: 59;
    stroke-dasharray: 59 236;
  }
  100% {
    stroke-dashoffset: 68;
    stroke-dasharray: 59 236;
  }
}

@keyframes stroke-animation-reverse {
  0% {
    stroke-dashoffset: 68;
    stroke-dasharray: 59 236;
  }
  50% {
    stroke-dashoffset: 290;
    stroke-dasharray: 25 270;
  }
  65% {
    stroke-dashoffset: 295;
    stroke-dasharray: 25 270;
  }
  100% {
    stroke-dashoffset: 290;
    stroke-dasharray: 25 270;
  }
}

`

class MenuButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  handleClick() {
    this.setState({ open: !this.state.open });
  }

  render() {
    return (
      <MenuButtonContainer onClick={this.props.onClick ? this.props.onClick : () => { this.handleClick(); }} >
        <input checked={this.state.open} type="checkbox" />
        <svg>
          <use xlinkHref="#menu-button" />
          <use xlinkHref="#menu-button" />
        </svg>
      </MenuButtonContainer>
    );
  }
}

export default MenuButton
