import React, {Component} from 'react'
import styled from 'styled-components';

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const PlayButtonContainer = styled.div`
  align-items: center;
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  height: 26px;
  justify-content: center;
  left: 50%;
  padding: 14px 16px 14px 24px;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 24px;
  z-index: 10;

  &:before {
    content: "";
    position: absolute;
    z-index: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    animation: pulse-border 2s ease-out infinite;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    display: block;
    width: 60px;
    height: 60px;
    background: rgba(255,255,255,0.0);
    border-radius: 50%;
    transition: all 200ms;
    border: 2px solid white;
  }

  span {
    display: block;
    position: relative;
    z-index: 3;
    width: 0;
    height: 0;
    border-left: 24px solid ${colors.white};
    border-top: 13px solid transparent;
    border-bottom: 13px solid transparent;
  }

  @keyframes pulse-border {
    0% {
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1);
      background: white;
      opacity: 0;
    }
    30% {
      background: ${colors.calloutTert};
      opacity: 0.6;
    }
    100% {
      background: white;
      transform: translateX(-50%) translateY(-50%) translateZ(0) scale(1.66);
      opacity: 0;
    }
  }
`

class PlayButton extends Component {
  render() {
    return(

      <PlayButtonContainer>
        <span></span>
      </PlayButtonContainer>

    )
  }
}

export default PlayButton
