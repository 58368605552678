import React, {Component} from 'react'
import styled, {css} from 'styled-components'
// import SpotifyPlayer from 'react-spotify-player';

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Components
import AlbumCallsToAction from "../AlbumCallsToAction"

// Styled Components
const AlbumContainer = styled.div`
  display: flex;
  align-items: baseline;
  flex-direction: column;
  width: 30%;

  ${queryBelow("phone", css`
    width: 100%;
  `)}
`

const AlbumArt = styled.div`
  background-image: ${props => `url(${props.art})`};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 1em;
  padding-bottom: 100%;
  width: 100%;
`

class Album extends Component {
  render() {
    const {
      albumArt,
      // albumTitle,
      spotifyAlbumCode,
      mainCtaHref,
      mainCtaText,
      streamLink,
      streamLinkText
    } = this.props;

    // const spotifyAlbumBase = "https://embed.spotify.com/oembed?url=spotify:album:";
    // const spotifyUrl = spotifyAlbumBase + spotifyAlbumCode;

    return (
      <AlbumContainer>
        <AlbumArt art={albumArt} />
        <AlbumCallsToAction
          spotifyAlbumCode={spotifyAlbumCode}
          mainCtaText={mainCtaText}
          mainCtaHref={mainCtaHref}
          streamLinkText={streamLinkText}
          streamLink={streamLink}
        />
      </AlbumContainer>
    );
  }
}

export default Album;
