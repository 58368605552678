import React from 'react';
import styled, { css } from 'styled-components';

// Utilities
import { queryBelow, queryAbove } from "../../utilities/mediaQuery"

const MenuContainer = styled.div`
  color: $background-alt;
  flexDirection: column;
  left: 0;
  opacity: 0.95;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 8;

  ${queryAbove("bigPhone", css`
    display: none;
  `)}

  ${queryBelow("bigPhone", css`
    display: ${props => props.open ? 'flex' : 'none'};
  `)}
`

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open ? this.props.open : false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.state.open) {
      this.setState({ open: nextProps.open });
    }
  }

  render() {
    return (
      <MenuContainer open={this.state.open}>
        {this.props.children}
      </MenuContainer>
    );
  }
}

export default Menu
