import React, {Component} from 'react'
import styled, {css} from 'styled-components'
import SpotifyPlayer from 'react-spotify-player';

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// size may also be a plain string using the presets 'large' or 'compact'
const size = 'compact'
// const view = 'list'; // 'list' or 'coverart'
const theme = 'white'; // or 'white'

// Styled Components
const AlbumCTAContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  justify-content: center;

  ${queryBelow("tablet", css`
    justify-content: center;
  `)}

  ${queryBelow("phone", css`
    max-width: 100%;
    min-width: 100%;
  `)}

  a {
    display: flex;
    font-size: 1.2em;
    margin-bottom: 1em;
    width: 100%;

    svg {
      height: 0.4em;
      margin: 0.2em 0.4em 0.2em 0;
      width: 0.4em;
      position: relative;
      top: 2px;

      &, use {
        fill: currentColor;
      }
    }
  }
`

const Spotify = styled.div`
  display: inline-block;
  margin: 0.6em 0 1.2em;
  width: 100%;

  iframe {
    max-width: 100%;
    width: 100%;
  }
`

class AlbumCallsToAction extends Component {
  render() {
    const {
      spotifyAlbumCode,
      mainCtaHref,
      mainCtaText,
      streamLink,
      streamLinkText
    } = this.props;

    return (
      <AlbumCTAContainer>
        {
          spotifyAlbumCode && (
            <Spotify>
              <SpotifyPlayer
                uri={"spotify:album:" + spotifyAlbumCode}
                size={size}
                theme={theme}
              />
            </Spotify>
          )
        }
        {
          mainCtaText && (
            <a className="cta main-cta" href={mainCtaHref} target="_blank" rel="noopener noreferrer">
              {mainCtaText}
            </a>
          )
        }
        {
          streamLinkText && (
            <a className="stream-album" href={streamLink} target="_blank" rel="noopener noreferrer">
              <svg><use xlinkHref="#external-link" /></svg>
              <span>{streamLinkText}</span>
            </a>
          )
        }
      </AlbumCTAContainer>
    );
  }
}

export default AlbumCallsToAction;
