import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'
// import SpotifyPlayer from 'react-spotify-player';

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Assets
import GoingPlacesImage from '../../assets/images/going-places/going-places-cover.jpg'

// Components
import GoingPlacesMarquee from '../GoingPlacesMarquee'
import AlbumCallsToAction from '../AlbumCallsToAction'

// Styled Components
const GoingPlaces = styled.section`
  background-color: rgba(0,0,0,0.96);
  padding: 0;
`

const GoingPlacesWrap = styled.div`
  margin: 2em auto;
  padding: 1em;
  position: relative;
  width: 100%;

  ${queryBelow("bigPhone", css`
    padding: 2em 0;
    width: 100%;
  `)}

  &:before {
    background-image: linear-gradient(-105deg, ${colors.callout}, ${colors.calloutAlt});
    content: "";
    height: calc(100% + 2em);
    left: 0;
    opacity: 0.86;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &:after {
    background-blend-mode: multiply;
    background-color: #22353c;
    background-image: url(${GoingPlacesImage});
    background-position: center top;
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.9;
    position: absolute;
    top: 0;
    width: calc(100% - 2em);
    margin: 1em;
  }
`

const GoingPlacesHeader = styled.div`
  color: ${colors.white};
  margin: 4em 0 3em;
  position: relative;
  text-align: center;
  z-index: 1;
`

const GoingPlacesHeading = styled.h4`
  color: ${colors.white};
  margin: 0 0 -0.5em;
`

const GoingPlacesLogo = styled.svg`
  &, use {
    fill: ${colors.calloutAltBright};
    height: 7em;
    width: 20em;

    ${queryBelow("bigPhone", css`
      width: 16em;
    `)}
  }
`

const ContentContainer = styled.div`
  display: flex;
  margin: 3em auto;
  position: relative;
  justify-content: space-between;
  width: 80%;
  max-width: 54em;
  z-index: 1;

  ${queryBelow("bigPhone", css`
    align-items: center;
    flex-direction: column;
  `)}

  p {
    color: ${colors.white};
    font-family: "Quincy CF";
    font-size: 1.2em;
    font-weight: 400;
  }
`

const AlbumCallsToActionContainer = styled.div`
  max-width: 20em;
`

const Writeup = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 2em;
  max-width: 30em;
  width: 55%;

  ${queryBelow("tablet", css`
    width: 100%;
  `)}

  ${queryBelow("bigPhone", css`
    margin-left: 0;
    max-width: 20em;
  `)}
`

// Logo
class GoingPlacesCallout extends Component {
  render() {
    const { writeup } = this.props;

    return(
      <GoingPlaces>
        <GoingPlacesWrap>
          <GoingPlacesHeader>
            <GoingPlacesHeading>Debut Album</GoingPlacesHeading>
            <GoingPlacesLogo>
              <use xlinkHref="#going-places-logo" />
            </GoingPlacesLogo>
          </GoingPlacesHeader>
          <GoingPlacesMarquee />
          <ContentContainer>
            <AlbumCallsToActionContainer>
              <AlbumCallsToAction
                spotifyAlbumCode="11yl8uOnBc5TvvSEmAvNcc"
                mainCtaText="Get the Vinyl"
                mainCtaHref="https://blissnova.bigcartel.com/product/going-places-180-gram-vinyl"
                streamLinkText="Stream the Album"
                streamLink="https://ffm.to/goingplaces"
              />
            </AlbumCallsToActionContainer>
            {
              writeup && (
                <Writeup dangerouslySetInnerHTML={{ __html: writeup }} />
              )
            }
          </ContentContainer>
        </GoingPlacesWrap>
      </GoingPlaces>
    )
  }
}

export default GoingPlacesCallout
