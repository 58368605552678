import React, {Component} from 'react'
import styled, {css} from 'styled-components'

// Utilities
// import { colors } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import Album from '../components/Album'
import SilenceCallout from '../components/SilenceCallout'
import GoingPlacesCallout from '../components/GoingPlacesCallout'

// Images
import AASayItNow from '../assets/images/album-art/Bliss-Nova-Say-It-Now.png'
import AALightAndShadow from '../assets/images/album-art/Bliss-Nova-Light-and-Shadow.jpg'
import AADoYouFeel from '../assets/images/album-art/Bliss-Nova-Do-You-Feel.jpg'

// Styled Components
const DiscographyContainer = styled.div`
  flex-direction: column;
  max-width: 78em;
  padding: 5em 0 0;
`

const DiscographyList = styled.div`
  display: flex;
  margin: 4em auto;
  width: 100%;
  justify-content: space-between;

  ${queryBelow("phone", css`
    flex-direction: column;
  `)}
`

class Discography extends Component {
  render() {
    return (
      <>
        <Header />
        <DiscographyContainer className="wrap">
          <SilenceCallout
            writeup="<p>The latest EP, Silence, expands Bliss Nova’s sonic horizons, wading into R&B-inflected D’n”B, nu-disco, house, vaporwave — their way to process extended isolation and introspection. Mixed by Alec Ness (Dizzy Fae) and mastered by Joe Lambert (Toro y Moi, Washed Out), the tight set of electro-pop tracks goes down smoothly — with a new EP to follow in 2022.</p>"
          />
          <GoingPlacesCallout
            writeup="<p>Bliss Nova’s debut album, Going Places, is available now on all streaming platforms.</p><p>Going Places is also available on limited edition 180 gram vinyl, available in either blue or orange, and includes a digital download code.</p>"
          />
          <DiscographyList>
            <Album
              albumArt={AASayItNow}
              albumTitle="Say It / Now (2017)"
              spotifyAlbumCode="4nzNoGuQ82hxmnqLqrid9n"
              streamLinkText="Stream or Buy"
              streamLink="https://ffm.to/sayitnow"
            />
            <Album
              albumArt={AALightAndShadow}
              albumTitle="Light & Shadow EP (2017)"
              spotifyAlbumCode="4fK4Hyizija7j1MgSlqLbD"
              streamLinkText="Stream or Buy"
              streamLink="https://ffm.to/lightshadow"
            />
            <Album
              albumArt={AADoYouFeel}
              albumTitle="Do You Feel EP (2015)"
              spotifyAlbumCode="7gwyjdLY0yellCd49wMPXY"
              streamLinkText="Stream or Buy"
              streamLink="https://ffm.to/doyoufeel"
            />
          </DiscographyList>
        </DiscographyContainer>
        <Footer />
      </>
    );
  }

}

export default Discography;
