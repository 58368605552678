import React, {Component} from 'react'
import styled, {css} from 'styled-components';

// Utilities
// import { colors } from "../../../utilities"
import { queryBelow } from "../../../utilities/mediaQuery"

// Assets
import heroVideo from  '../../../assets/video/ooalh.mp4'

// Components
// import PlayButton from '../../PlayButton';

// Styled Components
const HeroContainer = styled.div`
  align-items: center;
  display: flex;
  width: 100%;

  ${queryBelow("bigPhone", css``)}

  ${queryBelow("phone", css``)}

  &:after {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0));
    content: "";
    width: 100%;
    height: 50vh;
    position: absolute;
    top: -7em;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const HeroVideo = styled.video`
  bottom: 0 !important;
  height: 100vh;
  max-height: 4000%;
  max-width: 1000%;
  min-height: calc(100% + 1em);
  min-width: 100%;
  overflow: hidden;
  position: fixed;
  top: 0;

  ${queryBelow("bigPhone", css``)}

  ${queryBelow("phone", css``)}
`

// Hero
class Hero extends Component {
  myRef = React.createRef();

  render() {
    return(
      <HeroContainer>
        <HeroVideo autoBuffer playsInline autoPlay preLoad loop muted>
          <source src={heroVideo} type="video/mp4" />
        </HeroVideo>
      </HeroContainer>
    )
  }
}

export default Hero
