import React from "react";
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

// Pages
import Home from '../../Pages/Home'
import Bio from '../../Pages/Bio'
import Video from '../../Pages/Video'
import Discography from '../../Pages/Discography'
import OwnerOfALonelyHeart from '../../Pages/OwnerOfALonelyHeart'
import Tour from '../../Pages/Tour'

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "bio",
    element: <Bio />,
  },
  {
    path: "video",
    element: <Video />,
  },
  {
    path: "discography",
    element: <Discography />,
  },
  {
    path: "owner-of-a-lonely-heart",
    element: <OwnerOfALonelyHeart />,
  },
  {
    path: "bio",
    element: <Bio />,
  },
]);


createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

export default router;
