import React, {Component} from 'react'
import {Link} from 'react-router-dom'
import styled, {css} from 'styled-components'

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Assets
import BlissNeonPromoImage from  '../../assets/images/bliss-promo-neon-test.png'

// Components
// import GoingPlacesMarquee from '../GoingPlacesMarquee'

// Styled Components
const StyledBioCallout = styled.section`
  background-image: url(${BlissNeonPromoImage});
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: -10em;
  margin: -42em 0 0;
  padding: 38em 0 8em;

  ${queryBelow("phone", css`
    background-position: center bottom;
    background-size: 154vw;
    padding: 26em 0 4.5em;
  `)}

  ${queryBelow("smallPhone", css`
    background-size: 210vw;
  `)}
`

const BioCalloutWrap = styled.div`
  text-align: center;
  margin: 0 auto;
  max-width: 52em;
  position: relative;
  width: 90%;
  z-index: 3;

  ${queryBelow("phone", css`
    top: 6em;
    width: 97.5%;
  `)}
`

const BioBlockquote = styled.blockquote`
  display: inline;
  color: ${colors.white};
  font-size: 2em;
  font-style: italic;
  font-weight: 800;
  text-align: center;
  line-height: 1.4;

  ${queryBelow("phone", css`
    background-color: ${colors.calloutTert};
    font-size: 4.6vw;
  `)}

  ${queryBelow("smallPhone", css`
    font-size: 5.6vw;
  `)}
`

const BioLink = styled(Link)`
  margin: 2em 0 0;
`

// Logo
class BioCallout extends Component {
  render() {
    return(
      <StyledBioCallout>
        <BioCalloutWrap>
          <BioBlockquote>
            Suited equally to cocktails on the beach or driving by a neon-lit city skyline, their synesthetic synth-pop is filed by DJs alongside Toro y Moi, Washed Out and Tame Impala — yet the band’s outsider origins defy expectation.
          </BioBlockquote>
          <br></br>
          <BioLink className="cta" to="/bio">More on Bliss Nova</BioLink>
        </BioCalloutWrap>
      </StyledBioCallout>
    )
  }
}

export default BioCallout
