import React, {Component} from 'react'
import { HashLink as Link } from 'react-router-hash-link';
import styled, {css} from 'styled-components';

// Utilities
import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"

// Assets
// import heroImage from  '../../assets/images/hero.jpg'
import heroVideo from  '../../assets/video/headlights-web.mp4'
// import playSVG from  '../../assets/images/play.svg'
import LACineFest from  '../../assets/images/LACineFest.png'
import OnirosOfficialSelection from  '../../assets/images/ONIROS_OFFICIAL_SELECTION_white.png'
import DallasFilmFest from  '../../assets/images/dallas-independent-film-festival.png'
import Austin from  '../../assets/images/austin-laurel-win.png'

// Components
// import Logo from '../Logo';
import PlayButton from '../PlayButton';
// import ScrollDownArrow from '../ScrollDownArrow';

// Styled Components
const HeroContainer = styled.div`
  align-items: center;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  min-height: 101vh;
  position: absolute;
  top: 0;
  width: 100%;

  &:after {
    background-image: linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0));
    content: "";
    width: 100%;
    height: 50vh;
    position: absolute;
    top: -7em;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

const HeroVideo = styled.video`
  bottom: 0;
  height: 100vh;
  left: -20vh;
  max-height: 4000%;
  max-width: 1000%;
  min-height: calc(100% + 1em);
  min-width: 100%;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  width: 250vh;

  ${queryBelow("tablet", css`
    left: -60vh;
  `)}

  ${queryBelow("phone", css`
    left: -85vh;
  `)}
`

const HeroContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 7vw;
  position: relative;
  width: 100%;
  z-index: 1;
`

const PlayVideoLink = styled(Link)`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-style: italic;
  font-weight: 800;
  font-size: 1.4em;

  ${queryBelow("bigPhone", css`
    font-size: 1.2em;
  `)}

  ${queryBelow("smallerPhone", css`
    font-size: 1em;
  `)}

  &, &:hover {
    color: ${colors.white};
  }

  > span {
    line-height: 1.6;
    overflow: hidden;

    &,
    &:before,
    &:after {
      transition:
        transform 1s cubic-bezier(.23,1,.32,1),
        -webkit-transform 1s cubic-bezier(.23,1,.32,1);
    }

    &:before,
    &:after {
      bottom: 0.2em;
      content: "";
      height: 0.3em;
      position: absolute;
      width: 100%;
    }

    &:after {
      background-color: ${colors.callout};
      transform: translateX(-200%);
      z-index: -1;
    }
  }

  &:hover {
    > span {
      &:before {
        transform: translateX(100%);
      }

      &:after {
        transform: translateX(-100%);
      }
    }
  }
`

const PlayVideoLinkText = styled.span`
  margin-top: 8em;
  position: relative;
  z-index: 1;

  &:before {
    background-color: rgba(${colors.callout}, 0.6);
    bottom: -1px;
    content: "";
    height: 8px;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
`

const Laurels = styled.div`
  align-items: center;
  display: flex;
  margin: 1em auto 0;
  opacity: 0.8;
  width: 100%;
  justify-content: center;

  ${queryBelow("phone", css`
    flex-wrap: wrap;
  `)}
`

const Laurel = styled.div`
  height: 5em;
  width: 7em;
  background-image: ${props => `url(${props.img})`};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.8em;

  ${queryBelow("phone", css`
    margin: 0.25em;
  `)}
`

// Hero
class Hero extends Component {
  myRef = React.createRef();

  render() {
    return(
      <HeroContainer>
        <HeroVideo autoBuffer playsInline autoPlay preLoad loop muted>
          <source src={heroVideo} type="video/mp4" />
        </HeroVideo>

        <HeroContent>
          <PlayVideoLink to="/video#turning-off-the-headlights">
            <PlayButton />
            <PlayVideoLinkText>
              <span>Watch "Turning Off the Headlights"</span>
            </PlayVideoLinkText>
          </PlayVideoLink>
          <Laurels>
            <Laurel img={LACineFest} />
            <Laurel img={OnirosOfficialSelection} />
            <Laurel img={DallasFilmFest} />
            <Laurel img={Austin} />
          </Laurels>
        </HeroContent>
      </HeroContainer>
    )
  }
}

export default Hero
