import React, {Component} from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components';

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Styled Components
const LogoLinkContainer = styled(Link)`
  display: inline-flex;
  padding: 0 1em;
  position: relative;
  z-index: 9;

  svg {
    height: 3.2em;
    width: 6em;

    &, use {
      fill: ${colors.callout};
      animation-duration: 1.5s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-play-state: running;
    }
  }

  &:hover {
    svg {
      &, use {
        animation-name: logo-color-animation;
      }
    }
  }
`

// Logo
class LogoLink extends Component {
  render() {
    const { closeNav } = this.props;

    return(
      <LogoLinkContainer className="logo" onClick={closeNav} to="/">
        <svg><use xlinkHref="#logo" /></svg>
      </LogoLinkContainer>
    )
  }
}

export default LogoLink
