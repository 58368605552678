import React, { useState, useEffect } from 'react';
import Headroom from 'react-headroom'
import styled, { css } from 'styled-components';

// Utilities
// import { colors } from "../../utilities"
import { queryBelow } from "../../utilities/mediaQuery"
import ScrollToTop from "../../utilities/ScrollToTop"

// Components
import Nav from '../Nav'
import SVGSprite from '../SVGSprite'
import LogoLink from '../LogoLink'
import MenuButton from '../MenuButton'
import MobileNav from '../MobileNav'
import Menu from '../Menu'

// Styled Components
const HeaderContainer = styled.header`
  width: 100%;
  z-index: 7;
`

const HeadroomContainer = styled(Headroom)`
  .headroom {
    &,
    &--pinned,
    &--scrolled {
      background-color: rgba(0,0,0,0.9);
      z-index: 99999 !important;
    }

    &--unfixed {
      background-color: rgba(0,0,0,0);
      border: none;
      margin: 0;
    }
  }
`

const MobileHeader = styled.div`
  align-items: center;
  padding: 1em 0;
  display: none;
  width: 100%;
  justify-content: center;

  ${queryBelow("bigPhone", css`
    display: flex;
  `)}
`

// Header
const Header=()=> {
  const [menuOpen, setMenuOpen] = useState(false);

  return(
    <>
    <SVGSprite />
    <ScrollToTop />
    <HeaderContainer>
      <HeadroomContainer>
        <HeaderContainer>
          <MobileHeader>
            <MenuButton open={menuOpen} onClick={() => setMenuOpen(!menuOpen)} />
            <LogoLink closeNav={() => setMenuOpen(false)} />
            <Menu open={menuOpen}>
              <MobileNav closeNav={() => setMenuOpen(false)} />
            </Menu>
          </MobileHeader>
          <Nav />
        </HeaderContainer>
      </HeadroomContainer>
    </HeaderContainer>
    </>
  )
}

export default Header
