import React, {Component} from 'react'
import styled, {css} from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link';
import YouTube from 'react-youtube';
import ReactPlayer from "react-player";
import {Helmet} from "react-helmet";

// Utilities
import { colors } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import Hero from '../components/OwnerOfALonelyHeart/Hero'
import VideoContainer from '../components/VideoContainer'

// Images
// import MetaImage from '../assets/images/OOALH-meta.jpg'
import AAOwnerOfALonelyHeart from '../assets/images/album-art/Bliss-Nova-owner-of-a-lonely-heart.jpg'

// Styled Components
const OwnerOfALonelyHeartContainer = styled.div``

const ReleaseBar = styled.div`
  background-color: rgba(0,0,0, 0);
  display: flex;
  flex-direction: column;
  margin: 8em auto 0;
  padding: 3em 10%;
  position: relative;
  width: 100%;
  z-index: 1;

  ${queryBelow("phone", css`
    margin-top: 6em;
  `)}

  &:after {
    background-image: linear-gradient(to top,rgba(0,0,0,0.98),rgba(0,0,0,0) 86%);
    content: "";
    height: 100vh;
    left: 0;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`

const ReleaseOverview = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`

const AlbumArt = styled.div`
  background-image: ${props => `url(${props.art})`};
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 17em;
  width: 17em;
  margin-bottom: 2em;

  ${queryBelow("phone", css`
    height: 14em;
    width: 14em;
  `)}
`

const ReleaseInfo = styled.div`
  text-align: center;
  max-width: 32em;
  margin-bottom: 2em;

  h4 {
    color: ${colors.callout};
    margin-bottom: 0.4em;
  }

  h1 {
    font-size: 2.6em;
    margin-bottom: 0.6em;
  }

  p {
    margin-bottom: 0;
  }
`

const ReleaseCTA = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2em;

  ${queryBelow("phone", css`
    flex-direction: column;
  `)}
`

const PrimaryCTA = styled(Link)`
  margin-right: 1em;

  ${queryBelow("phone", css`
    margin: 0 0 1em;
    width: 100%;
  `)}
`

const StreamCTA = styled.a`
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.6em 1.6em 0.8em;
  width: auto;
  font-family: "Eighties";
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: white;
  border: 2px solid white;

  &, span {
    line-height: 1.4;
  }

  svg {
    height: 0.6em;
    width: 0.6em;
    margin-right: 0.4em;

    &, use {
      fill: white;
    }
  }
`

const MusicVideo = styled.section`
  background-color: rgba(0,0,0,0.98);
  padding: 4em 0;
`

const StoryContainer = styled.section`
  background-color: rgba(255,255,255,0.8);
  padding: 6em 0;
  position: relative;
  overflow: hidden;

  &:before {
    background-image: ${props => `url(${props.background})`};
    position: absolute;
    top: -10%;
    left: -10%;
    width: 150%;
    height: 150%;
    content: "";
  }
`

const Story = styled.div`
  background: #FFFFFC;
  padding: 3em 4em;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  width: 90%;
  max-width: 48em;

  ${queryBelow("phone", css`
    padding: 3em 10%;
  `)}

  h2 {
    color: ${colors.calloutAlt};
    font-family: "Eighties";
    font-size: 5em;
    font-weight: lighter;
    line-height: 0.8;
    mix-blend-mode: difference;
    text-transform: uppercase;
    -moz-background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: ${colors.calloutQuat};
    -webkit-text-stroke-width: 2px;
    color: #FFFFFF;
    z-index: 1;
    left: -2em;
    position: relative;
    margin-bottom: 2rem;
    width: 150%;

    ${queryBelow("phone", css`
      -webkit-text-stroke-width: 1px;
      font-size: 2.8em;
      left: 0;
      width: 100%;
      margin-bottom: 1rem;
    `)}
  }

  h4 {
    color: ${colors.callout};
    font-weight: normal;
  }

  ul {
    li {
      padding-left: 1em;
      position: relative;

      &:before {
        content: "•";
        position: absolute;
        left: 0;
        top: 0.3em;
      }
    }
  }
`

// Home
class OwnerOfALonelyHeart extends Component {
  render() {
    const opts = {
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoPlay: 0,
        color: "white",
        modestBranding: 1,
        rel: 0,
        title: 0,
      }
    };

    return (
      <>
        <Header />
        <OwnerOfALonelyHeartContainer>
          <Helmet>
            <title>BLISS NOVA • Owner of a Lonely Heart</title>
          </Helmet>
          <Hero />

          <ReleaseBar>
            <ReleaseOverview>
              <AlbumArt art={AAOwnerOfALonelyHeart} />
              <ReleaseInfo>
                <h4>Available Now</h4>
                <h1>Owner of a Lonely Heart</h1>
                <p>New single featuring a cover of "Owner of a Lonely Heart" by Yes. Includes the bonus track, “Silver City.”</p>
              </ReleaseInfo>
              <ReleaseCTA>
                <PrimaryCTA className="cta main-cta" to="#music-video">
                  Watch the Video
                </PrimaryCTA>
                <StreamCTA href="https://ffm.to/ooalh" target="_blank" rel="noopener noreferrer">
                  Stream Now
                </StreamCTA>
              </ReleaseCTA>
            </ReleaseOverview>
          </ReleaseBar>

          <MusicVideo>
            <div className="wrap" id="music-video">
              <VideoContainer>
                <YouTube
                  videoId="0-KtHBMW4-s"
                  opts={opts}
                  onReady={this._onReady}
                />
              </VideoContainer>
            </div>
          </MusicVideo>

          <StoryContainer background={AAOwnerOfALonelyHeart}>
            <Story className="dark-cursor">
              <h4>“Owner of a Lonely Heart” <br />by Bliss Nova</h4>
              <h2>The Music Video</h2>
              <p>The original song by Yes has two music videos, and both are a joy to watch. You have to admit: there’s something undeniably fun about self-serious prog-rock guys, over a decade into their career, awkwardly shuffling to synthy dance-pop about self-actualization.</p>
              <p>For our video, we wanted to explore the same themes but from a metalevel — to exist in the overlap of optimistic self-idealization and ironic self-awareness. Mica of Magdalena Bay helped bring this wacky world into being, where (almost) anything is possible.</p>

              <h2>The Inspiration Behind the Cover</h2>
              <p>It was a blustery October evening, almost exactly two years ago, and I was sitting on my friend’s porch. We were listening to Pink Floyd radio on Pandora.</p>
              <p>We were nodding along, half distractedly discussing our appreciation for classic rock that “still holds up” when the next track’s intro kicked in — a dubbed-out funky drum fill, followed by a handful of orchestral hits and campy synth lines; I was intrigued.</p>
              <p>I asked who it was. He showed me his phone.</p>
              <p>“<em>Owner of a Lonely Heart</em>” ... by <em>Yes</em>.</p>

              <h2>Yes?</h2>
              <p>Yes was one of those bands I knew existed but who were never caught in my orbit of attention. Aside from skimming their first few albums — the type of prog rock I didn’t quite <em>get</em>  — I’d never listened to a full song of theirs. So I was surprised to hear a punchy pop anthem that sounded more Michael Jackson and much less Emerson, Lake & Palmer.</p>
              <p>That break right at the beginning was all I needed to hear. I rushed home, sampled it and mocked up a reggae version without so much as listening to the track all the way through.</p>

              <VideoContainer>
                <ReactPlayer url="https://soundcloud.com/bliss-nova/owner-of-a-lonely-heart/" />
              </VideoContainer>

              <p>A few weeks later I stopped by my friend’s place again, this time with a demo — and some fun facts. I showed him my version and told him what I’d learned about the original:</p>
              <ul>
                <li>
                  <p>Trevor Horn of synth-pop group The Buggles (remember “Video Killed the Radio Star”?) produced the album “Owner” is on, <em>90125</em> — not to mention that The Buggles joined Yes for the album prior, appropriately called <em>Drama</em>, to take the places of singer Jon Anderson and keyboardist Rick Wakeman, who both had recently quit. They later rejoined and put Horn at the helm for production of their comeback album.</p>
                </li>
                <li>
                  <p>Horn swears he had to beg Yes to do “Owner.” “That’s a hit chorus,” he supposedly told them. They denied his side of the story, but either way, good thing they did it.</p>
                </li>
                <li>
                  <p>That drum break was actually a sample of “Kool Is Back” by Funk Inc. (then a little-known funk outfit out of Indianapolis, Indiana) — and, according to Questlove, its usage on “Owner” was the first ever of a sample as a breakbeat and not just a sound effect.</p>
                </li>
                <li>
                  <p>Michael Jackson later sampled “Owner” for his song “D.S.” on <em>HIStory</em>. When you have the ear of the King of Pop...</p>
                </li>
              </ul>
              <p>Fun facts, right? Then my friend dropped <em>his</em> fun fact: apparently Yes had a song about Toledo, our hometown.</p>

              <h2>“Toledo’s gotta be...”</h2>
              <p>Now, not many pop songs have name-dropped Toledo, and the ones that have, like John Denver’s “Saturday Night in Toledo, Ohio,” are often a kind of passive-aggressive dig. Maybe it’s because we’re not quite Detroit or Cleveland. Or maybe it’s just a synecdoche for the Midwest.</p>
              <p>Curious, we looked it up. It turns out that the song, “Our Song,” was the b-side to “Owner of a Lonely Heart.” As a Toledoan, I felt like it made Toledo sound kind of cool, with lyrics like “Toledo’s got to be the silver city.”</p>
              <p>So what made a British prog band interested in Toledo?</p>
              <p>As the story goes, when Yes played the Toledo Sports Arena on a sweltering July evening in 1977, the venue got so hot inside — up to 126℉ — it left a permanent impression on the band; several years later, they wrote a song (to accompany “Owner,” their highest-charting single) about the power of music, making sure to reference the city where it happened.</p>
              <p>How our cover came to be brings to mind the backwards nature of creative work — something I’ve puzzled at over the years. A thing isn’t a thing until it’s a thing. Destiny? Will? I’m not qualified to give an answer, in this case or in any other. To reference the lyrics of “Owner,” I’m happy to “just receive it.”</p>

              <h2>“Silver City”</h2>
              <VideoContainer>
                <ReactPlayer url="https://soundcloud.com/bliss-nova/silver-city" />
              </VideoContainer>
              <p>As an accompaniment to the “Owner” cover, I did an interpretive ambient sampling of “Our Song,” the b-side, incorporating my own field recordings of personally meaningful Toledo spots. I titled it “Silver City” as a nod to the b-side’s fidelity to the transformative power of music to heal people — something I’m willing to believe in, too.</p>
              <p>— DMT</p>

            </Story>
          </StoryContainer>

        </OwnerOfALonelyHeartContainer>
        <Footer />
      </>
    );
  }
}

export default OwnerOfALonelyHeart;
