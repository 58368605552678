import React from 'react';
import styled, {css} from 'styled-components'
import {Helmet} from 'react-helmet'

// Utilities
// import { colors } from "../utilities"
import { queryBelow } from "../utilities/mediaQuery"

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import Hero from '../components/Hero'
import GoingPlacesCallout from '../components/GoingPlacesCallout'
import BioCallout from '../components/BioCallout'

// Styled Components
const HomeContainer = styled.div`
  display: inline-block;
  width: 100%;
`

const GoingPlacesContainer = styled.div`
  > section {
    margin-top: 66em;
    padding: 0 5% 24em;

    ${queryBelow("bigPhone", css`
      margin-top: calc(100vh + 4em);
      padding: 0 0 20em;
    `)}

    ${queryBelow("smallPhone", css`
      margin-top: calc(100vh + 0);
    `)}

    ${queryBelow("smallerPhone", css`
      margin-top: calc(100vh - 4em);
    `)}

    &:before {
      background-image: linear-gradient(to top, rgba(0,0,0,0.96) 3%, rgba(0,0,0,0) 86%);
      content: "";
      height: 35vh;
      left: 0;
      position: absolute;
      top: -35vh;
      width: 100%;
    }
  }
`

// Home
function Home() {

  return (
    <HomeContainer>
      <Helmet>
        <title>Bliss Nova</title>
        <link rel="canonical" href="https://blissnova.com" />

        <title>BLISS NOVA</title>
        <link rel="shortcut icon" type="image/x-icon" href="%PUBLIC_URL%/favicon.png" />
        <link rel="canonical" href="https://www.blissnovamusic.com"/>
        <meta itemprop="name" content="BLISS NOVA"/>
        <meta itemprop="url" content="https://www.blissnovamusic.com"/>
        <meta itemprop="description" content="Bliss Nova is a two-piece synesthetic synth-pop band from Toledo, Ohio, consisting of brothers Daniel and Joel Trzcinski."/>
        <meta itemprop="thumbnailUrl" content="%PUBLIC_URL%/bliss-nova.jpg"/>
        <link rel="image_src" href="%PUBLIC_URL%/bliss-nova.jpg" />
        <meta itemprop="image" content="%PUBLIC_URL%/bliss-nova.jpg"/>

        <meta property="og:site_name" content="BLISS NOVA"/>
        <meta property="og:title" content="BLISS NOVA"/>
        <meta property="og:url" content="https://www.blissnovamusic.com"/>
        <meta property="og:type" content="website"/>
        <meta property="og:description" content="Bliss Nova is a two-piece synesthetic synth-pop band from Toledo, Ohio, consisting of brothers Daniel and Joel Trzcinski."/>
        <meta property="og:image" content="%PUBLIC_URL%/bliss-nova.jpg"/>
        <meta property="og:image:width" content="1500"/>
        <meta property="og:image:height" content="1000"/>

        <meta name="twitter:title" content="BLISS NOVA"/>
        <meta name="twitter:image" content="%PUBLIC_URL%/bliss-nova.jpg"/>
        <meta name="twitter:url" content="https://www.blissnovamusic.com"/>
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:description" content="Bliss Nova is a two-piece synesthetic synth-pop band from Toledo, Ohio, consisting of brothers Daniel and Joel Trzcinski."/>
        <meta name="description" content="Bliss Nova is a two-piece synesthetic synth-pop band from Toledo, Ohio,
        consisting of brothers Daniel and Joel Trzcinski." />
      </Helmet>
      <Header/>
      <Hero />
      <GoingPlacesContainer>
        <GoingPlacesCallout
          writeup="<p><em>Going Places</em> serves up smoggy, nostalgia-tinged euphoria awash with vintage sparkle. Longtime collaborator Brennan Willis (Ohio Recording Company) co-produced and mixed the record, while mastering legend Dave Cooley (J Dilla, M83, Blood Orange) gave it a sonic polish.</p>
          <p>With the new-age aesthetic of Eno and Gondry-esque surrealism, the album marks a fresh sonic evolution, uniting eclectic influences from electro funk and cosmic jazz to ambient electronica, neo-soul and r&b. A supernova of samples, field recordings, lush percussion and catchy pentatonic tropes shimmer on the surface, with murkier depths and humor underneath.</p>"
        />
      </GoingPlacesContainer>
      <BioCallout />
      <Footer />
    </HomeContainer>
  );
}

export default Home;
