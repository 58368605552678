import React, { Component } from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components';

// Utilities
import { colors } from "../../utilities"
// import { queryBelow } from "../../utilities/mediaQuery"

// Components
import SocialNav from "../SocialNav"

// Styled Components
const MobileNavigation = styled.nav`
  background: black;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 8em 2.4em;
  position: absolute;
  width: 100%;
`

const NavItem = styled(NavLink)`
  font-family: "Eighties";
  font-size: 11vh;
  padding: 0;
  color: ${colors.white};
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;

  &:hover {
    color: ${colors.calloutQuat};
    -webkit-text-stroke: 2px ${colors.calloutQuat};
  }
`

const NavItemExternal = styled.a`
  font-family: "Eighties";
  font-size: 11vh;
  padding: 0;
  color: ${colors.white};
  -webkit-text-stroke: 1px rgb(255, 255, 255);
  -webkit-text-fill-color: rgba(255, 255, 255, 0);
  text-transform: uppercase;

  &:hover {
    color: ${colors.calloutQuat};
    -webkit-text-stroke: 2px ${colors.calloutQuat};
  }
`

const SocialNavContainer = styled(SocialNav)`
  margin: 10vh 0;
`

// Logo
class MobileNav extends Component {
  render() {
    const { closeNav } = this.props;

    return(
      <MobileNavigation>
        <NavItem onClick={closeNav} to="/bio">
          <span>Bio</span>
        </NavItem>
        <NavItem onClick={closeNav} to="/video">
          <span>Video</span>
        </NavItem>
        <NavItem onClick={closeNav} to="/discography">
          <span>Music</span>
        </NavItem>
        <NavItemExternal href="https://blissnova.bigcartel.com/" target="_blank">
          <span>Merch</span>
        </NavItemExternal>

        <SocialNavContainer />
      </MobileNavigation>
    )
  }
}

export default MobileNav
