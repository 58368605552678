import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'

// Assets
import GoingPlacesInsert from '../../assets/images/going-places/going-places-insert.png'
import GoingPlacesOrangeVinyl from '../../assets/images/going-places/going-places-blue-vinyl.png'
import GoingPlacesBlueVinyl from '../../assets/images/going-places/going-places-orange-vinyl.png'
import GoingPlacesCover from '../../assets/images/going-places/going-places-vinyl-cover-mock.png'
import GoingPlacesFull from '../../assets/images/going-places/going-places-full.png'
// import GoingPlacesImage from '../../assets/images/going-places/going-places-cover.jpg'

// Styled Components
const GoingPlacesMarqueeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  height: 20em;
  margin: 0 auto;
  position: relative;
  width: 100%;
  z-index: 1;
`

const GoingPlacesMarqueeTrack = styled.div`
  animation: marquee 38s linear infinite;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 169em;

  &:last-of-type {
    left: 169em;
  }

  img {
    margin-left: 2em;
  }
`

const GoingPlacesVinylPackageImg = styled.img`
  height: auto;
  width: 60em;
`

const GoingPlacesCoverImg = styled.img`
  width: 18.6em;
`

const GoingPlacesInsertImg = styled.img`
  width: 18.6em;
`

const GoingPlacesOrangeVinylImg = styled.img`
  width: 31em;
`

const GoingPlacesBlueVinylImg = styled.img`
  width: 31em;
`

// Logo
class GoingPlacesMarquee extends Component {
  render() {
    return(
      <GoingPlacesMarqueeContainer className={this.props.className}>
        <GoingPlacesMarqueeTrack>
          <GoingPlacesBlueVinylImg src={GoingPlacesBlueVinyl} />
          <GoingPlacesCoverImg src={GoingPlacesCover} />
          <GoingPlacesInsertImg src={GoingPlacesInsert} />
          <GoingPlacesOrangeVinylImg src={GoingPlacesOrangeVinyl} />
          <GoingPlacesVinylPackageImg src={GoingPlacesFull} />
        </GoingPlacesMarqueeTrack>
        <GoingPlacesMarqueeTrack>
          <GoingPlacesBlueVinylImg src={GoingPlacesBlueVinyl} />
          <GoingPlacesCoverImg src={GoingPlacesCover} />
          <GoingPlacesInsertImg src={GoingPlacesInsert} />
          <GoingPlacesOrangeVinylImg src={GoingPlacesOrangeVinyl} />
          <GoingPlacesVinylPackageImg src={GoingPlacesFull} />
        </GoingPlacesMarqueeTrack>
      </GoingPlacesMarqueeContainer>
    )
  }
}

export default GoingPlacesMarquee
