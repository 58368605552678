import React, {Component} from 'react'
// import {Link} from 'react-router-dom'
import styled from 'styled-components'
import YouTube from 'react-youtube';

// Components
import Header from '../components/Header'
import Footer from '../components/Footer'
import VideoContainer from '../components/VideoContainer'

// Styled Components
const VideosContainer = styled.div`
  flex-direction: column;
  margin-bottom:  4em;
  padding: 5em 0 0;
`

class Video extends Component {
  render() {
    const opts = {
      // height: '390',
      // width: '640',
      playerVars: { // https://developers.google.com/youtube/player_parameters
        autoPlay: 0,
        color: "white",
        modestBranding: 1,
        rel: 0,
        title: 0,
      }
    };

    return (
      <>
        <Header/>
        <VideosContainer className="wrap">
          <VideoContainer id="every-move">
            <YouTube
              videoId="RVAjnJfz5hs"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer id="always-be-young">
            <YouTube
              videoId="W0k7aD5Nid4"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer id="feel-so-high">
            <YouTube
              videoId="k0bCU-OBHUU"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer id="get-u">
            <YouTube
              videoId="81oyd60wyM8"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer id="silence">
            <YouTube
              videoId="PbNknIWLoFU"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer id="owner-of-a-lonely-heart">
            <YouTube
              videoId="0-KtHBMW4-s"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <hr id="turning-off-the-headlights" />
          <VideoContainer>
            <YouTube
              videoId="D486JVmtohw"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>

          <VideoContainer>
            <YouTube
              videoId="YLA17Bib-3U"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>
          <VideoContainer>
            <YouTube
              videoId="vuEChHs9QOM"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>
          <VideoContainer>
            <YouTube
              videoId="h7Sg_x5kt3c"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>
          <VideoContainer id="say-it">
            <YouTube
              videoId="31oyholYKw4"
              opts={opts}
              onReady={this._onReady}
            />
          </VideoContainer>
        </VideosContainer>
        <Footer />
      </>
    );
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

export default Video;
